import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import Form from '../../components/Form';
import InputRadioButton from '../../components/InputRadioButton';
import InputText from '../../components/InputText';
import InputSelect from '../../components/InputSelect';
import Warning from '../../components/Warning';
import LabelInfo from '../../components/LabelInfo';
import InputRadioYesNo from '../../components/InputRadioYesNo';

import { createStation, editStation } from '../../redux/actions/stations';
import { getOwnerParties } from '../../redux/actions/userInfo';
import { resetErrors } from '../../redux/actions/resetErrors';
import { searchNodes } from '../../redux/actions/nodes';
import { cutOffTimes } from '../../lib/times';
import t from '../../lib/translate';
import { ROLE_DI } from '../../api/util';
import Error from "../../components/Error";

const NAME = 'name';
const OWNERPARTY_KEY = 'ownerPartyKey';
const NODES = 'nodes';
const SORTING_DEGREE_ID = 'sortingDegreeId';
const ALIAS_ALLOCATION_METHOD_ID = 'aliasAllocationMethodId';
const EXTERNAL_STATION_ID = 'externalStationId';
const SCANNING_CUT_OFF = 'scanningCutOff';
const REUSE_ALIAS = 'reuseAlias';
const LOCATION_CONTEXT_ID = 'locationContextId';
const STREET = 'streetNameAndNumber';
const POSTAL_CODE = 'postalCode';
const CITY = 'city';
const COUNTRY_CODE = 'countryCode';
const VALIDATION_FAILED = 'validationFailed';
const TRANSPORT_POINTS = 'transportPoints';
const MULTIPLE_DISTRIBUTION_DAYS_ENABLED = 'multipleDistributionDaysEnabled';

const errorMapping = new Map([
  [NAME, 'station.name.required'],
  [TRANSPORT_POINTS, 'transport.points.required'],
  [SORTING_DEGREE_ID, 'sorting.degree.required'],
  [ALIAS_ALLOCATION_METHOD_ID, 'alias.allocation.method.id.required'],
  [EXTERNAL_STATION_ID, 'external.station.id.required'],
  [POSTAL_CODE, 'postal.code.required'],
  [CITY, 'city.required'],
  [COUNTRY_CODE, 'country.code.required'],
]);

const DEFAULT_VALUES = {
  [NAME]: '',
  [OWNERPARTY_KEY]: '',
  nodes: [],
  hasDiRole: false,
  ownerParties: [],
  initiallySelectedRouteNodes: [],
  [SORTING_DEGREE_ID]: undefined,
  [ALIAS_ALLOCATION_METHOD_ID]: undefined,
  [EXTERNAL_STATION_ID]: '',
  [SCANNING_CUT_OFF]: undefined,
  [LOCATION_CONTEXT_ID]: '',
  [REUSE_ALIAS]: false,
  [MULTIPLE_DISTRIBUTION_DAYS_ENABLED]: false,
  [STREET]: '',
  [POSTAL_CODE]: '',
  [CITY]: '',
  [COUNTRY_CODE]: '',
};

class StationForm extends React.Component {
  constructor(props) {
    super(props);

    const station = {
      ...DEFAULT_VALUES,
      ...props.station,
      ...(props.station && props.station.address),
    };

    this.state = {
      ...station,
      hasDiRole: props.user.grantedRoles.includes(ROLE_DI),
      ownerPartyKey: station.ownerParty ? station.ownerParty.key : props.user.activeOwnerParty.key,
      selectedOwnerParty: station.ownerParty
        ? station.ownerParty.key
        : props.user.activeOwnerParty.key,
      nodeSearchTerm: '',
      selectedNodes: station.nodes.map((sn) => ({
        externalId: sn.externalId,
        nodeTypeCode: sn.nodeTypeCode,
        value: sn.nodeId,
        label: sn.name,
      })),
      expanded: false,
    };

    this.searchNodesAndSetState = debounce(this.searchNodesAndSetState, 500);
  }

  componentDidMount() {
    this.searchNodesAndSetState();
    this.props.getOwnerParties();
  }

  searchNodesAndSetState = () => {
    const { nodeSearchTerm, ownerPartyKey } = this.state;
    this.props.searchNodes(nodeSearchTerm, ownerPartyKey);
  };

  updateFieldValue = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.updateFieldValue(name, value);
  };

  handleCutOffChange = (e) => {
    this.updateFieldValue([SCANNING_CUT_OFF], e ? e.value : e);
  };

  handleNodeSearchTermChange = (value) => {
    if (value !== this.state.nodeSearchTerm) {
      this.setState({ nodeSearchTerm: value });
      this.searchNodesAndSetState();
    }
  };

  handleSelectNodes = (selectedNodes) => {
    this.setState({ selectedNodes });
    // Reset search term for next node
    if (this.state.nodeSearchTerm !== '') {
      this.setState({ nodeSearchTerm: '' });
      this.searchNodesAndSetState();
    }
  };

  handleSelectOwnerParty = (selectedOwnerParty) => {
    this.setState({
      selectedOwnerParty,
      ownerPartyKey: selectedOwnerParty.value,
    });
    this.searchNodesAndSetState();
  };

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  enableSubmit = () => {
    return !this.state.isLoading;
  };

  validationError = () => {
    const required = [
      NAME,
      SORTING_DEGREE_ID,
      ALIAS_ALLOCATION_METHOD_ID,
      EXTERNAL_STATION_ID,
      POSTAL_CODE,
      CITY,
      COUNTRY_CODE,
    ];

    if (this.state.selectedNodes.length === 0) {
      return errorMapping.get(TRANSPORT_POINTS);
    }

    for (const field of required) {
      const failedValidation = this.validateStateContainsValue(field);
      if (failedValidation) {
        return errorMapping.get(failedValidation);
      }
    }

    return undefined;
  };

  validateStateContainsValue = (fieldName) => {
    return this.state[fieldName] ? undefined : fieldName;
  };

  toApiRequest = () => {
    const address =
      this.state[POSTAL_CODE] && this.state[CITY] && this.state[COUNTRY_CODE]
        ? {
            [STREET]: this.state[STREET],
            [POSTAL_CODE]: this.state[POSTAL_CODE],
            [CITY]: this.state[CITY],
            [COUNTRY_CODE]: this.state[COUNTRY_CODE],
          }
        : null;

    return {
      [NAME]: this.state[NAME],
      [OWNERPARTY_KEY]: this.state.selectedOwnerParty ? this.state.selectedOwnerParty.value : null,
      [NODES]: this.state.selectedNodes,
      [ALIAS_ALLOCATION_METHOD_ID]: this.state[ALIAS_ALLOCATION_METHOD_ID],
      [SORTING_DEGREE_ID]: this.state[SORTING_DEGREE_ID],
      [EXTERNAL_STATION_ID]: this.state[EXTERNAL_STATION_ID],
      [SCANNING_CUT_OFF]: this.state[SCANNING_CUT_OFF],
      [LOCATION_CONTEXT_ID]: this.state[LOCATION_CONTEXT_ID],
      [REUSE_ALIAS]: this.state[REUSE_ALIAS],
      [MULTIPLE_DISTRIBUTION_DAYS_ENABLED]: this.state[MULTIPLE_DISTRIBUTION_DAYS_ENABLED],
      address,
    };
  };

  closeModalAndResetForm = () => {
    this.searchNodesAndSetState();
    this.props.resetErrors();
    this.props.finished(this.props.name);
  };

  handleSubmit = () => {
    const { station, editStation, createStation } = this.props;

    if (this.enableSubmit() && !this.validationError()) {
      const req = this.toApiRequest();

      station
        ? editStation(station.stationId, req, this.closeModalAndResetForm)
        : createStation(req, this.closeModalAndResetForm);
    } else {
      this.updateFieldValue([VALIDATION_FAILED], this.validationError());
    }
  };

  render() {
    const {
      isLoading,
      error,
      aliasAllocationMethods,
      sortingDegrees,
      mappedNodeSearchResult,
      station,
      nodesLoading,
      usedSortingDegreeIds,
      ownerParties,
      user,
    } = this.props;

    return (
      <Form
        isLoading={isLoading}
        error={error}
        label={station ? 'station.edit.header' : 'station.new.header'}
        enableSubmit={this.enableSubmit()}
        onClose={this.closeModalAndResetForm}
        onSubmit={this.handleSubmit}>
        {!station && user.activeOwnerParty && (
          <div className='input-group'>
            <LabelInfo label={t('station.label.ownerparty')} />
            {user.activeOwnerParty.name}
          </div>
        )}
        <InputText
          name={NAME}
          value={this.state[NAME]}
          label='station.new.station.name'
          maxLength='40'
          handleChange={this.handleChange}
        />

        {station && this.state.hasDiRole && (
          <InputSelect
            selectedKey={this.state.selectedOwnerParty}
            onChange={this.handleSelectOwnerParty}
            options={ownerParties}
            label='station.ownerparty'
            info='station.ownerparty.info'
          />
        )}

        <InputSelect
          value={this.state.selectedNodes}
          isLoading={nodesLoading}
          onChange={this.handleSelectNodes}
          onInputChange={this.handleNodeSearchTermChange}
          options={mappedNodeSearchResult}
          label='station.new.nodes'
          info='station.new.nodes.info'
          isMulti
        />

        <InputRadioButton
          name={SORTING_DEGREE_ID}
          label='station.new.sorting.degree'
          info='station.new.sorting.degree.info'
          value={this.state[SORTING_DEGREE_ID]}
          options={sortingDegrees.map((sd) => ({
            value: sd.sortingDegreeId,
            label: t(`sorting.degree.type.${sd.sortingDegreeId}`),
          }))}
          handleChange={this.updateFieldValue}
        />

        <InputRadioButton
          name={ALIAS_ALLOCATION_METHOD_ID}
          label='station.new.alias.type'
          info='station.new.alias.type.info'
          value={this.state[ALIAS_ALLOCATION_METHOD_ID]}
          options={aliasAllocationMethods.map((aam) => ({
            value: aam.aliasAllocationMethodId,
            label: `alias.type.${aam.aliasAllocationMethodId}`,
          }))}
          handleChange={this.updateFieldValue}
        />

        <InputText
          name={EXTERNAL_STATION_ID}
          type='number'
          min={1}
          info='station.external.station.info'
          value={+this.state[EXTERNAL_STATION_ID]}
          label='station.external.station'
          handleChange={this.handleChange}
        />

        <InputRadioYesNo
          name={MULTIPLE_DISTRIBUTION_DAYS_ENABLED}
          label={'station.multiple.distribution.days.enabled'}
          value={this.state[MULTIPLE_DISTRIBUTION_DAYS_ENABLED]}
          handleChange={this.updateFieldValue}
        />

        <LabelInfo label='station.address' info='station.address.info' group />

        <InputText
          name={STREET}
          value={this.state[STREET]}
          label='station.address.street'
          maxLength='105'
          handleChange={this.handleChange}
        />

        <div className='input-group-inline'>
          <InputText
            name={POSTAL_CODE}
            label='station.address.postal.code'
            value={this.state[POSTAL_CODE]}
            maxLength='9'
            handleChange={this.handleChange}
          />

          <InputText
            name={CITY}
            label='station.address.city'
            value={this.state[CITY]}
            maxLength='45'
            handleChange={this.handleChange}
          />

          <InputText
            name={COUNTRY_CODE}
            label='station.address.country.code'
            value={this.state[COUNTRY_CODE]}
            handleChange={this.handleChange}
            maxLength='3'
          />
        </div>

        <div className='form-expand' onClick={this.toggleExpanded}>
          {t('station.advanced')}
        </div>

        {this.state.expanded && (
          <>
            <InputSelect
              value={
                this.state[SCANNING_CUT_OFF] && {
                  value: this.state[SCANNING_CUT_OFF],
                  label: this.state[SCANNING_CUT_OFF],
                }
              }
              onChange={this.handleCutOffChange}
              options={cutOffTimes}
              label='station.scanning.cut.off'
              info='station.scanning.cut.off.info'
              isClearable
            />

            <InputText
              name={LOCATION_CONTEXT_ID}
              value={this.state[LOCATION_CONTEXT_ID] || ''}
              label='station.location.context.id'
              maxLength='10'
              info='station.location.context.id.info'
              handleChange={this.handleChange}
            />
          </>
        )}

        {this.state[VALIDATION_FAILED] && (
          <Error error={{ errorKey: this.state[VALIDATION_FAILED] }} />
        )}

        {usedSortingDegreeIds && usedSortingDegreeIds.includes(this.state[SORTING_DEGREE_ID]) && (
          <Warning
            className='input-group'
            message={t(
              'station.new.sorting.degree.warning',
              sortingDegrees.find((sd) => sd.sortingDegreeId === this.state[SORTING_DEGREE_ID]).name
            )}
          />
        )}
      </Form>
    );
  }
}

const mapDispatchToProps = {
  editStation,
  createStation,
  resetErrors,
  searchNodes,
  getOwnerParties,
};

const mapStateToProps = (state, props) => ({
  isLoading: state.isLoading.primary,
  user: state.userInfo.user,
  nodesLoading: state.isLoading.secondary,
  error: props.station ? state.error.station.modal : state.error.stations.newStation,
  aliasAllocationMethods: state.staticData.aliasAllocationMethods,
  sortingDegrees: state.staticData.sortingDegrees.filter((sd) => !sd.parcelSortingDegree),
  mappedNodeSearchResult:
    state.nodes &&
    state.nodes.map((node) => ({
      externalId: node.externalId,
      nodeTypeCode: node.nodeTypeCode,
      value: node.nodeId,
      label: `${node.nodeTypeCode} - ${node.name}`,
    })),
  ownerParties: state.userInfo.ownerParties.map((op) => ({
    value: op.key,
    label: op.name,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(StationForm);
