import React, { type FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { type NodeIdentifier } from '../../types';
import LabelInfo from '../../components/LabelInfo';
import t from '../../lib/translate';
import { getFilterOptions, orderTransportUpdate } from '../../redux/actions/transport';
import LoadingButton from '../../components/LoadingButton';
import Error from '../../components/Error';

const TransportUpdateForm: FC = () => {
  const { filterOptions, error, loading } = useAppSelector((state) => state.transport);
  const [selected, setSelected] = useState<NodeIdentifier[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // @ts-expect-error Not typed due to old JS
    dispatch(getFilterOptions());
  }, []);

  const handleSelectOne = (selectedValue: NodeIdentifier): void => {
    setSelected([...selected, selectedValue]);
  };

  const handleRemoveSome = (removed: NodeIdentifier[]): void => {
    setSelected(selected.filter((opt) => !removed.includes(opt)));
  };

  const handleSubmit = (): void => {
    // @ts-expect-error Not typed due to old JS
    dispatch(orderTransportUpdate({ filters: selected }));
    setSelected([]);
  };

  return (
    <>
      <p>{t('transport.description')}</p>
      <LabelInfo label={t('transport.select.label')} />
      <Select
        isMulti={true}
        isClearable={true}
        options={filterOptions}
        value={selected}
        getOptionLabel={(opt) => opt.label}
        getOptionValue={(opt) => opt.externalId}
        onChange={(_, { option, removedValue, removedValues }) => {
          if (option) {
            handleSelectOne(option);
          } else if (removedValue) {
            handleRemoveSome([removedValue]);
          } else if (removedValues) {
            handleRemoveSome(removedValues as NodeIdentifier[]);
          }
        }}
      />
      {error && <Error error={error} withMargin={false} />}
      <LoadingButton
        label={t('transport.submit')}
        isLoading={loading}
        onClick={handleSubmit}
        disabled={false}
      />
    </>
  );
};

export default TransportUpdateForm;
